import { useQuery } from '@apollo/client';
import BigNumber from 'bignumber.js';
import { TABLE_POLL_INTERVAL } from '../constants';

import { isBoostFeatureEnabled } from '../featureFlags';
import { getBoostFeesAggregatedByLp } from '../queries/boost';
import { getFilteredLpAccountsWithBalancesQuery } from '../queries/lp';
import { accountOrdersToLiquidityUsd, type OpenOrdersCacheResult, TokenAmount } from '../utils';
import { type ChainflipAsset } from '../utils/chainflip';
import { useChainflipAssetPrices } from '.';

export default function useLpStats(lpIdSs58?: string) {
  const { prices: assetPrices } = useChainflipAssetPrices();

  const { data: liquidityData, loading: liquidityDataLoading } = useQuery(
    getFilteredLpAccountsWithBalancesQuery,
    {
      pollInterval: 10_000,
      context: { clientName: 'statechainCache' },
      variables: { filter: lpIdSs58 },
    },
  );

  const { data: boostFees, loading: boostFeesLoading } = useQuery(getBoostFeesAggregatedByLp, {
    pollInterval: TABLE_POLL_INTERVAL,
    context: { clientName: 'lpProcessor' },
    variables: { idSs58: lpIdSs58 },
    skip: !isBoostFeatureEnabled(),
  });

  const collectedBoostFees = (
    boostFees?.lps?.nodes.map((node) => Number(node.boostShares.aggregates?.sum?.feeUsd) || 0) ?? []
  ).reduce((a, b) => a + b, 0);

  const collectedLpFees = (liquidityData?.lps?.nodes ?? []).reduce(
    (acc, curr) => acc + Number(curr.earnedFeesValueUsd),
    0,
  );

  const pools = liquidityData?.pools?.nodes || [];
  const lpOpenOrders = (liquidityData?.lps?.nodes.map((node) => node.openOrders).flat() ??
    []) as OpenOrdersCacheResult[];

  const deployedLiquidityUsd = lpOpenOrders.reduce(
    (liquidity, { baseAsset, quoteAsset, orders }) => {
      const pool = pools.find((p) => p.baseAsset === baseAsset && p.quoteAsset === quoteAsset);
      return (
        liquidity +
        accountOrdersToLiquidityUsd(
          orders,
          baseAsset,
          quoteAsset,
          assetPrices[baseAsset] ?? 0,
          assetPrices[quoteAsset] ?? 0,
          pool?.rangeOrderPrice ?? 0,
        )
      );
    },
    0,
  );

  const lpBalances = liquidityData?.lps?.nodes.map((node) => node.balances.nodes).flat() ?? [];
  const boostBalances =
    liquidityData?.lps?.nodes.map((node) => node.boostBalances.nodes).flat() ?? [];

  const undeployedLiquidityUsd = lpBalances.reduce((acc, curr) => {
    const asset = curr.asset as ChainflipAsset;
    const usdValue =
      TokenAmount.fromAsset(curr.amount, asset)
        ?.mul(assetPrices[asset] ?? 0)
        .toNumber() ?? 0;
    return acc + usdValue;
  }, 0);

  const boostLiquidityUsd = BigNumber.sum(
    0,
    ...boostBalances.flatMap((balance) => [
      balance.availableAmountValueUsd,
      balance.unavailableAmountValueUsd,
    ]),
  ).toNumber();

  return {
    collectedBoostFees,
    totalCollectedFeesUsd: collectedLpFees + collectedBoostFees,
    deployedLiquidityUsd,
    undeployedLiquidityUsd,
    boostLiquidityUsd,
    totalLiquidityUsd: deployedLiquidityUsd + undeployedLiquidityUsd + boostLiquidityUsd,
    liquidityDataLoading,
    boostFeesLoading,
  };
}
