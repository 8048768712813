import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { getMainnetTokenEquivalent, getTokenKey, type Token } from '@/shared/assets/tokens';
import { useInterval } from '@/shared/hooks';
import useBoolean from '@/shared/hooks/useBoolean';
import { fetchTokenPrices } from '../utils/fetchTokenPrices';
import { noop } from '../utils/functions';

type Timeout = ReturnType<typeof setTimeout>;
type PriceOracleContextValue = {
  watchTokens: (tokens: Token[]) => () => void;
  getPrice: (token: Token) => number | undefined;
  isLoading: boolean;
};
const PriceOracleContext = createContext<PriceOracleContextValue | undefined>(undefined);

export const PriceOracleProvider = ({
  children,
  refreshInterval = 10_000,
}: {
  children: ReactNode;
  refreshInterval?: number;
}) => {
  const { current: watchedTokens } = useRef<Record<string, { count: number; token: Token }>>({});
  const [loadedPrices, setLoadedPrices] = useState<Record<string, number>>({});
  const {
    value: isLoading,
    setTrue: setIsLoadingTrue,
    setFalse: setIsLoadingFalse,
  } = useBoolean(false);

  const refreshPrices = useCallback(() => {
    const tokens = Object.values(watchedTokens)
      .filter((entry) => entry.count > 0)
      .map((entry) => entry.token);

    setIsLoadingTrue();
    fetchTokenPrices(tokens.map(getMainnetTokenEquivalent))
      .then((prices) => {
        const newPrices = prices.reduce(
          (prev, cur) => ({ ...prev, [getTokenKey(cur)]: cur.usdPrice }),
          {} as Record<string, number>,
        );
        setLoadedPrices((oldPrices) => ({ ...oldPrices, ...newPrices }));
      })
      .catch(noop)
      .finally(() => {
        setTimeout(() => {
          setIsLoadingFalse();
        }, 300);
      });
  }, []);

  // debounce refreshPrices call to prevent multiple calls during a single render
  const refreshPricesTimeout = useRef<Timeout>();
  const debouncedRefreshPrices = useCallback(() => {
    if (refreshPricesTimeout.current) clearTimeout(refreshPricesTimeout.current);
    refreshPricesTimeout.current = setTimeout(refreshPrices, 100);
  }, []);

  const watchTokens = useCallback((tokens: Token[]) => {
    const tokenKeys = tokens.map(getTokenKey);

    for (const [index, tokenKey] of tokenKeys.entries()) {
      if (!watchedTokens[tokenKey] || watchedTokens[tokenKey].count <= 0) {
        watchedTokens[tokenKey] = { token: tokens[index], count: 0 };
        debouncedRefreshPrices();
      }
      watchedTokens[tokenKey].count += 1;
    }

    return () => {
      for (const tokenKey of tokenKeys) {
        if (watchedTokens[tokenKey]) watchedTokens[tokenKey].count -= 1;
      }
    };
  }, []);

  const getPrice = useCallback(
    (token: Token) => loadedPrices[getTokenKey(getMainnetTokenEquivalent(token))],
    [loadedPrices],
  );

  useInterval(debouncedRefreshPrices, refreshInterval);

  const context = useMemo(
    () => ({
      watchTokens,
      getPrice,
      isLoading,
    }),
    [watchTokens, getPrice, isLoading],
  );

  return <PriceOracleContext.Provider value={context}>{children}</PriceOracleContext.Provider>;
};

export const usePriceOracle = () => {
  const context = useContext(PriceOracleContext);
  if (context === undefined) {
    throw new Error('usePriceOracle must be used within a PriceOracleContext');
  }

  return context;
};
