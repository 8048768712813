import { useQuery } from '@apollo/client';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { cacheClient, lpProcessorClient } from '@/shared/graphql/client';
import {
  getAllBoostPoolBalancesForAccount,
  getAllBoostPoolsCacheQuery,
  getBoostPoolApyQuery,
  getEarnedFeesAndVolumeForPools,
  getEarnedFeesByPoolForAccount,
} from '@/shared/queries/boost';
import { getAccountBoostPoolBalanceQueryKey } from '@/shared/queryKeys';
import { isBoostFeatureEnabled } from '../featureFlags';
import { type GetBoostPoolApyQuery } from '../graphql/generated/graphql';
import { TokenAmount, isNotNullish, isNullish } from '../utils';
import { type ChainflipAsset } from '../utils/chainflip';

export type BoostPoolInfoProps = {
  asset: ChainflipAsset;
  feeTier: number;
  account?: string;
};
export default function useBoostPoolInfo({ asset, feeTier, account }: BoostPoolInfoProps) {
  const { data: cacheData } = useQuery(getAllBoostPoolsCacheQuery, {
    context: { clientName: 'statechainCache' },
    pollInterval: 30_000,
  });

  const { data: accountBalanceData } = useTanstackQuery({
    queryKey: getAccountBoostPoolBalanceQueryKey(account as string),
    queryFn: () =>
      cacheClient.request(getAllBoostPoolBalancesForAccount, { accountId: account as string }),
    refetchInterval: 5_000,
    enabled: isNotNullish(account),
  });

  const { data: apyData } = useTanstackQuery({
    queryKey: ['BOOST_APY', asset, feeTier],
    queryFn: (): Promise<GetBoostPoolApyQuery> =>
      lpProcessorClient.request(getBoostPoolApyQuery, {
        asset: asset as ChainflipAsset,
        feeTierPips: feeTier as number,
      }),
    select: (queryResult: GetBoostPoolApyQuery) => {
      const filtered = queryResult.allBoostPools?.nodes[0].apys?.nodes.filter(
        (apy) => apy.avgLiquidity !== '0',
      );
      const ratios = filtered?.map((apy) =>
        new BigNumber(apy.feesEarned).div(apy.avgLiquidity).times(100).toNumber(),
      );
      const last24hRatios = filtered
        ?.slice(0, 24)
        .map((apy) => new BigNumber(apy.feesEarned).div(apy.avgLiquidity).times(100).toNumber());

      return {
        threeDayAverage: ratios && ratios.reduce((acc, curr) => acc + curr, 0) / ratios.length,
        oneDayAverage:
          last24hRatios &&
          last24hRatios.reduce((acc, curr) => acc + curr, 0) / last24hRatios.length,
      };
    },
    enabled: Boolean(asset) && !isNullish(feeTier) && isBoostFeatureEnabled(),
  });

  const cachePool = cacheData?.pools?.nodes.find(
    (pool) => pool.asset === asset && pool.feeTierPips === feeTier,
  );
  const balanceInfo = accountBalanceData?.balances?.nodes.find(
    (balance) => balance.pool.asset === asset && balance.pool.feeTierPips === feeTier,
  );
  const liquidityInfo = isNotNullish(account) ? balanceInfo : cachePool;

  const { data: volumeAndFeeData } = useQuery(getEarnedFeesAndVolumeForPools, {
    context: { clientName: 'lpProcessor' },
    pollInterval: 30_000,
    skip: isNotNullish(account),
  });

  const { data: lpVolumeAndFeeData } = useQuery(getEarnedFeesByPoolForAccount, {
    variables: { accountId: account as string },
    context: { clientName: 'lpProcessor' },
    pollInterval: 30_000,
    skip: isNullish(account),
  });

  const poolVolumeAndFees = volumeAndFeeData?.pools?.nodes.find(
    (info) => info.asset === asset && info.feeTierPips === feeTier,
  )?.shares.aggregates?.sum;
  const poolId = lpVolumeAndFeeData?.boostPools?.nodes.find(
    (pool) => pool.asset === asset && pool.feeTierPips === feeTier,
  )?.id;

  const lpVolumeAndFees = lpVolumeAndFeeData?.account?.boostShares?.groupedAggregates?.find(
    (info) => info.keys![0] === String(poolId),
  )?.sum;

  const volumeAndFees = poolVolumeAndFees ?? lpVolumeAndFees;

  const liquidity = TokenAmount.fromAsset(
    BigNumber.sum(liquidityInfo?.availableAmount ?? 0, liquidityInfo?.unavailableAmount ?? 0),
    asset,
  );
  const liquidityUsd =
    Number(liquidityInfo?.availableAmountValueUsd ?? 0) +
    Number(liquidityInfo?.unavailableAmountValueUsd ?? 0);
  const volume = TokenAmount.fromAsset(volumeAndFees?.amount ?? 0, asset);
  const fees = TokenAmount.fromAsset(volumeAndFees?.fee ?? 0, asset);

  const totalLiquidity = TokenAmount.fromAsset(
    BigNumber.sum(cachePool?.availableAmount ?? 0, cachePool?.unavailableAmount ?? 0),
    asset,
  );

  return {
    liquidity,
    liquidityUsd,
    totalLiquidity,
    volume,
    fees,
    volumeUsd: volumeAndFees?.amountUsd,
    feesUsd: volumeAndFees?.feeUsd,
    apy: apyData,
    // apollo query client loading states are shit
    liquidityLoading: liquidityInfo == null,
    volumeLoading: volumeAndFees == null,
    feesLoading: volumeAndFees == null,
  };
}
