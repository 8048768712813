import * as Sentry from '@sentry/react';
import BigNumber from 'bignumber.js';
import { isNullish } from './guards';
import { assert } from './helpers';
import { ALL_ZEROS, LEADING_ZEROS, PERIOD_WITH_TRAILING_ZEROS, TRAILING_ZEROS } from './regex';

export const clamp = (value: number | string, min: number, max: number) => {
  const startingValue = typeof value === 'string' ? Number.parseInt(value, 10) : value;
  return Number.isNaN(startingValue) ? min : Math.min(Math.max(startingValue, min), max);
};

export function formatWithCommas(value: string | number): string;
export function formatWithCommas(value: string | number | null): string | null;
export function formatWithCommas(value: string | number | undefined): string | undefined;
export function formatWithCommas(
  value: string | number | null | undefined,
): string | null | undefined;
export function formatWithCommas(
  value: string | number | null | undefined,
): string | null | undefined {
  return value == null ? value : new BigNumber(value).toFormat();
}

export function formatUsdValue(value: BigNumber.Value): string;
export function formatUsdValue(value: null): null;
export function formatUsdValue(value: undefined): undefined;
export function formatUsdValue(
  value: BigNumber.Value | null | undefined,
): string | null | undefined;
export function formatUsdValue(
  value: BigNumber.Value | null | undefined,
): string | null | undefined {
  if (value == null) return value;

  const usdAmount = new BigNumber(value);

  assert(usdAmount.gte(0), 'negative amounts not supported');

  if (!usdAmount.eq(0) && usdAmount.lt(0.01)) return `<$0.01`;

  return `$${usdAmount.toFormat(2)}`;
}

export function formatLudicrousUsdValue(value: BigNumber.Value): string;
export function formatLudicrousUsdValue(value: null): null;
export function formatLudicrousUsdValue(value: undefined): undefined;
export function formatLudicrousUsdValue(
  value: BigNumber.Value | null | undefined,
): string | null | undefined;
export function formatLudicrousUsdValue(
  value: BigNumber.Value | null | undefined,
): string | null | undefined {
  if (value == null) return value;

  const usdAmount = new BigNumber(value);

  assert(usdAmount.gte(0), 'negative amounts not supported');

  if (usdAmount.lte(1_000_000_000)) return formatUsdValue(usdAmount);

  const amountString = usdAmount.toFixed(0);

  const match = /^(\d{1,3})((?:\d{3})+)$/.exec(amountString);

  if (match === null) {
    Sentry.captureException(new Error(`Failed to format ludicrous USD value`), {
      extra: { amountString, usdAmount: usdAmount.toFixed(), value },
    });
    return '$-';
  }

  const [, display, rest] = match;

  return `$${display}e${rest.length}`;
}

export function formatWithNumeral(value: string | number, mandatoryDecimals?: boolean): string;
export function formatWithNumeral(
  value: null | undefined,
  mandatoryDecimals?: boolean,
): null | undefined;
export function formatWithNumeral(
  value: string | number | null | undefined,
  mandatoryDecimals?: boolean,
): string | number | null | undefined;
export function formatWithNumeral(
  inputValue: string | number | null | undefined,
  mandatoryDecimals = false,
): string | number | null | undefined {
  if (isNullish(inputValue) || Number.isNaN(inputValue)) return inputValue;

  let value = new BigNumber(inputValue);
  let suffix = '';

  if (value.gte(1_000_000_000_000)) {
    value = value.shiftedBy(-12);
    suffix = 'T';
  } else if (value.gte(1_000_000_000)) {
    value = value.shiftedBy(-9);
    suffix = 'B';
  } else if (value.gte(10_000_000)) {
    value = value.shiftedBy(-6);
    suffix = 'M';
  }

  return `${value.decimalPlaces(2).toFormat(mandatoryDecimals ? 2 : undefined)}${suffix}`;
}

export const normalizeZeros = (string: string): string =>
  string
    .replace(PERIOD_WITH_TRAILING_ZEROS, '')
    .replace(ALL_ZEROS, '')
    .replace(LEADING_ZEROS, '')
    .replace(TRAILING_ZEROS, '$1');

export const safeParseInt = (value: string | null | undefined, radix = 10): number | null => {
  if (isNullish(value)) return null;
  const parsed = Number.parseInt(value, radix);
  return Number.isNaN(parsed) ? null : parsed;
};

export const parseIntOrThrow = (value: string | number) => {
  if (typeof value === 'number') return value;
  const n = Number.parseInt(value, 10);
  if (Number.isSafeInteger(n)) return n;
  throw new Error('Failed to safely parse integer');
};

export const pipsToPercentString = (pips: number): string => `${(pips / 100).toFixed(2)}%`;
