import { gql } from '@/shared/graphql/generated';

export const getAllBoostPoolsCacheQuery = gql(/* GraphQL */ `
  query getAllBoostPoolsCache {
    pools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
        availableAmount
        availableAmountValueUsd
        unavailableAmount
        unavailableAmountValueUsd
        boosters: boostBalancesByPoolId {
          totalCount
        }
      }
    }
  }
`);

// cache query
export const getBoostPoolCacheQuery = gql(/* GraphQL */ `
  query GetBoostPoolCache($chainflipAsset: ChainflipAsset!, $feeTierPips: Int!) {
    pools: allBoostPools(
      filter: {
        feeTierPips: { equalTo: $feeTierPips }
        and: { asset: { equalTo: $chainflipAsset } }
      }
    ) {
      nodes {
        id
        availableAmount
        unavailableAmount
        availableAmountValueUsd
        unavailableAmountValueUsd
      }
    }
  }
`);

// cache query
export const getBoostBalancesQuery = gql(/* GraphQL */ `
  query GetBoostBalances($poolId: Int!) {
    balances: allBoostBalances(condition: { poolId: $poolId }, orderBy: POOL_SHARE_DESC) {
      nodes {
        lpIdSs58
        poolShare
        availableAmount
        unavailableAmount
        availableAmountValueUsd
        unavailableAmountValueUsd
      }
    }
  }
`);

// cache query
export const getAllBoostPoolBalancesForAccount = gql(/* GraphQL */ `
  query AllBoostBalancesForAccount($accountId: String!) {
    balances: allBoostBalances(condition: { lpIdSs58: $accountId }) {
      nodes {
        pool: boostPoolByPoolId {
          id
          asset
          feeTierPips
          availableAmount
          unavailableAmount
        }
        availableAmountValueUsd
        unavailableAmountValueUsd
        unavailableAmount
        availableAmount
        poolShare
      }
    }
  }
`);

export const getBoostPoolLiquidityBalanceChangeQuery = gql(/* GraphQL */ `
  query GetBoostPoolLiquidityBalanceChange(
    $asset: ChainflipAsset!
    $feeTierPips: Int!
    $first: Int
    $offset: Int
  ) {
    pool: boostPoolByAssetAndFeeTierPips(asset: $asset, feeTierPips: $feeTierPips) {
      balanceChanges: boostLiquidityBalanceChangesByBoostPoolId(
        filter: { type: { in: [FUNDS_ADDED, WITHDRAWAL, DELAYED_WITHDRAWAL] } }
        orderBy: EVENT_ID_DESC
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            amount
            amountUsd
            type
            event: eventByEventId {
              block
              indexInBlock
              timestamp
            }
            lp: accountByLiquidityProviderId {
              idSs58
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getBoostSharesByPoolQuery = gql(/* GraphQL */ `
  query GetBoostSharesByPool($asset: ChainflipAsset!, $feeTierPips: Int!) {
    pools: allBoostPools(
      filter: { asset: { equalTo: $asset }, and: { feeTierPips: { equalTo: $feeTierPips } } }
    ) {
      nodes {
        boostShares: boostSharesByBoostPoolId(orderBy: FEE_USD_DESC) {
          aggregates: groupedAggregates(groupBy: LIQUIDITY_PROVIDER_ID) {
            sum {
              feeUsd
              fee
              amountUsd
              amount
            }
            keys
          }
          nodes {
            account: accountByLiquidityProviderId {
              id
              idSs58
            }
          }
        }
      }
    }
  }
`);

export const getPoolBoostSharesQuery = gql(/* GraphQL */ `
  query GetPoolBoosts($asset: ChainflipAsset!, $feeTierPips: Int!, $first: Int, $offset: Int) {
    pool: boostPoolByAssetAndFeeTierPips(asset: $asset, feeTierPips: $feeTierPips) {
      boostShares: boostPoolBoostSharesByBoostPoolId(
        orderBy: BOOST_ID_DESC
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            id
            amount
            amountUsd
            fee
            feeUsd
            boost: boostByBoostId {
              event: eventByExecutedAtEventId {
                indexInBlock
                block
                timestamp
              }
              channel: depositChannelByDepositChannelId {
                issuedBlockId
                chain
                channelId
              }
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getAllBoostPoolIdsQuery = gql(/* GraphQL */ `
  query LpBoostPoolIds {
    pools: allBoostPools {
      nodes {
        id
        asset
        feeTierPips
      }
    }
  }
`);

export const getBoostSharesByAccountAndPoolQuery = gql(/* GraphQL */ `
  query GetBoostSharesByAccountAndPool(
    $accountId: String!
    $boostPoolId: Int
    $first: Int
    $offset: Int
  ) {
    account: accountByIdSs58(idSs58: $accountId) {
      shares: boostSharesByLiquidityProviderId(
        condition: { boostPoolId: $boostPoolId }
        orderBy: EXECUTED_AT_TIMESTAMP_DESC
        first: $first
        offset: $offset
      ) {
        edges {
          node {
            id
            amount
            amountUsd
            fee
            feeUsd
            boost: boostByBoostId {
              event: eventByExecutedAtEventId {
                indexInBlock
                block
                timestamp
              }
              channel: depositChannelByDepositChannelId {
                issuedBlockId
                chain
                channelId
              }
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);

export const getAccountBoostLiquidityBalanceChangesQuery = gql(/* GraphQL */ `
  query AccountBoostLiquidityBalanceChanges(
    $accountId: String!
    $boostPoolId: Int
    $first: Int
    $offset: Int
  ) {
    account: accountByIdSs58(idSs58: $accountId) {
      balanceChanges: boostLiquidityBalanceChangesByLiquidityProviderId(
        filter: { type: { in: [FUNDS_ADDED, WITHDRAWAL, DELAYED_WITHDRAWAL] } }
        condition: { boostPoolId: $boostPoolId }
        first: $first
        offset: $offset
        orderBy: EVENT_ID_DESC
      ) {
        edges {
          node {
            amount
            amountUsd
            type
            event: eventByEventId {
              block
              indexInBlock
              timestamp
            }
            lp: accountByLiquidityProviderId {
              idSs58
            }
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
          }
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
          startCursor
          hasPreviousPage
        }
      }
    }
  }
`);
export const getEarnedFeesByPoolForAccount = gql(/* GraphQL */ `
  query EarnedFeesByPoolForAccount($accountId: String!) {
    account: accountByIdSs58(idSs58: $accountId) {
      boostShares: boostSharesByLiquidityProviderId {
        groupedAggregates(groupBy: BOOST_POOL_ID) {
          keys
          sum {
            amount
            amountUsd
            fee
            feeUsd
          }
        }
      }
    }
    boostPools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
      }
    }
  }
`);
export const getEarnedFeesInPoolForAccount = gql(/* GraphQL */ `
  query EarnedFeesInPoolForAccount(
    $idSs58: String!
    $boostPoolId: Int
    $minTimestamp: Datetime!
    $maxTimestamp: Datetime!
    $groupBy: [BoostShareGroupBy!]!
  ) {
    account: accountByIdSs58(idSs58: $idSs58) {
      boostShares: boostSharesByLiquidityProviderId(
        filter: {
          and: [
            { executedAtTimestamp: { greaterThanOrEqualTo: $minTimestamp } }
            { executedAtTimestamp: { lessThanOrEqualTo: $maxTimestamp } }
          ]
        }
        condition: { boostPoolId: $boostPoolId }
        orderBy: EXECUTED_AT_TIMESTAMP_DESC
      ) {
        groupedAggregates(groupBy: $groupBy) {
          keys
          sum {
            fee
            feeUsd
          }
        }
      }
    }
  }
`);

export const getEarnedFeesByPool24hsForAccount = gql(/* GraphQL */ `
  query EarnedFeesByPool24hsForAccount($accountId: String!, $timestamp: Datetime!) {
    account: accountByIdSs58(idSs58: $accountId) {
      boostShares: boostSharesByLiquidityProviderId(
        filter: { executedAtTimestamp: { greaterThanOrEqualTo: $timestamp } }
      ) {
        groupedAggregates(groupBy: BOOST_POOL_ID) {
          keys
          sum {
            fee
            feeUsd
          }
        }
      }
    }
  }
`);

export const getEarnedFeesAndVolumeForPools = gql(/* GraphQL */ `
  query EarnedFeesAndVolumeForPools {
    pools: allBoostPools {
      nodes {
        id
        feeTierPips
        asset
        shares: boostPoolBoostSharesByBoostPoolId {
          aggregates {
            sum {
              amount
              amountUsd
              fee
              feeUsd
            }
          }
        }
      }
    }
  }
`);

export const getBoostPoolVolumeAndFeesAggregates = gql(/* GraphQL */ `
  query GetBoostPoolVolumeAndFeesAggregates(
    $boostPoolId: Int!
    $minTimestamp: Datetime!
    $maxTimestamp: Datetime!
    $groupBy: BoostPoolBoostShareGroupBy!
  ) {
    boostPools: allBoostPools(condition: { id: $boostPoolId }) {
      nodes {
        boostShares: boostPoolBoostSharesByBoostPoolId(
          filter: {
            and: [
              { executedAtTimestamp: { greaterThanOrEqualTo: $minTimestamp } }
              { executedAtTimestamp: { lessThanOrEqualTo: $maxTimestamp } }
            ]
          }
        ) {
          groupedAggregates(groupBy: [$groupBy]) {
            keys
            sum {
              amountUsd
              amount
              fee
              feeUsd
            }
          }
        }
      }
    }
  }
`);

export const getBoostPoolApyQuery = gql(/* GraphQL */ `
  query GetBoostPoolApy($asset: ChainflipAsset!, $feeTierPips: Int!) {
    allBoostPools(
      filter: {
        and: { asset: { equalTo: $asset }, and: { feeTierPips: { equalTo: $feeTierPips } } }
      }
    ) {
      nodes {
        # last 3 days
        apys: boostPoolApiesByBoostPoolId(orderBy: BLOCK_DESC, first: 72) {
          nodes {
            timestamp
            feesEarned
            avgLiquidity
          }
        }
      }
    }
  }
`);

export const getAllBoostPoolApyQuery = gql(/* GraphQL */ `
  query GetAllBoostPoolApy {
    allBoostPools {
      nodes {
        apys: boostPoolApiesByBoostPoolId(orderBy: BLOCK_DESC, first: 72) {
          nodes {
            pool: boostPoolByBoostPoolId {
              asset
              feeTierPips
            }
            feesEarned
            avgLiquidity
          }
        }
      }
    }
  }
`);

export const getBoostFeesAggregatedByLp = gql(/* GraphQL */ `
  query GetBoostFeesAggregatedByLp($idSs58: String) {
    lps: allAccounts(condition: { idSs58: $idSs58 }) {
      nodes {
        idSs58
        boostShares: boostSharesByLiquidityProviderId {
          aggregates {
            sum {
              feeUsd
            }
          }
        }
      }
    }
  }
`);
