import { type LpAccountWithBalancesFragment } from '@/shared/graphql/generated/graphql';
import { gql } from '../graphql/generated';

export type LpBalance = LpAccountWithBalancesFragment['balances']['nodes'][number];
export const getFilteredLpAccountsWithBalancesQuery = gql(/* GraphQL */ `
  query GetFilteredLpAccountsWithBalances($filter: String) {
    lps: allLpAccounts(
      filter: {
        or: [
          { idSs58: { includesInsensitive: $filter } }
          { alias: { includesInsensitive: $filter } }
        ]
      }
    ) {
      totalCount
      nodes {
        ...LpAccountWithBalances
      }
    }
    pools: allPools {
      nodes {
        ...Pool
      }
    }
  }
`);
